var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
// var countUpStatsDuration = 3000;

// var homeQuickGivingPreFooter = false;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

// var sidebarCards = false; // Make sure to set a card layout prefab for '.associatedRelatedPosts .associatedListing' and a card prefab to '.sidebarCard'

$('.homeFeatureDetailsWrapper p').each(function () {
  $('<span class="readMore">Read more</span>').insertAfter($(this));
})

$('.homeBox6 .homeFeatureDetailsWrapper > *').wrapAll('<div class="homeFeatureDetailsInner"></div>');
$('.homeBox7 .homeFeatureDetailsWrapper > *').wrapAll('<div class="homeFeatureDetailsInner"></div>');

$('document').ready(function () {
  $('.homeFeedsWrapper').insertAfter('.homefeaturecategory-homeboximpactstats');
})

if ($('body').hasClass('homepage')) {
  $('.Newslettersign-up').wrap('<div class="footerBox footerBox1 newsletterOuter">');
  $('.Newslettersign-up > *').wrapAll('<div class="newsletterInner">');
  $('.Newslettersign-up').css('background-image', "url(" + $('.Newslettersign-up img').attr('src') + ")");
}

$('.footerBox.Homepagequickgiving').addClass('homefeaturecategory-homeboxquickgiving');

$('.footerBox.Homepagequickgiving').find('> *:not(img)').wrapAll('<div class="quick-giving-text">');

$('.footerBox.Homepagequickgiving').find('> img').wrap('<div class="quick-giving-img">');

$('.footerBox:not(.footerBox1, .footerBox2)').wrapAll('<div class="footer-bottom-wrapper">');

$('document').ready(function () {
  if ($('body').hasClass('listing-without-feature')) {
    $('.breadcrumbWrapper, .title').wrapAll('<div class="titleWrapper">');
  }
  if ($('body').hasClass('listing-with-feature') && $('.carouselSlide').length == 1) {
    $('body').addClass('single-feature');
  }

  $('.newsletterInner > *').each(function() {
    isInView(this);
  });
})

// Wrap quick giving footer stuff outside of homepage, too
if (!$('body').hasClass('homepage')) {
  $('.homefeaturecategory-homeboxquickgiving > *:not(.mceNonEditable)').wrapAll(
    '<div class="homepage-quickgiving--copy" />'
  );
  $('.homefeaturecategory-homeboxquickgiving').wrapInner(
    '<div class="homepage-quickgiving--container" />'
  );
}

// Sidebar content
$(".postFeeds .associatedBox").each(function () {
  if ($(this).css("display") != "none") {
    $(this).addClass("listedPost").addClass("sidebarCard");
    $(this).find("h2, p").wrapAll('<div class="listedPostText">');
    let thisURL = $(this).find("h2 a").attr("href");
    $(this).attr("onclick", `window.location = "${thisURL}"`);
  }
});

// Quick giving in subsite body content
$('body.homepage.subsite .selectDonationAmount').each(function() {
  $(this)
    .find('.donationAmount:not(.donationAmountOther)')
    .wrapAll('<div class="donationAmountsWrapper" />');
});

if ($('body').hasClass('subsite')) {
  $('#h1SubsiteTitle').insertAfter('.mainLogo');
  // $('#menuMain').html($('#menuSub').html());
  $('#menuSub').insertAfter('#menuMain');
}

if ($('body').hasClass('listing-category-12-gifts')) {
  $(".headerText").prepend("<img src='/fresco/clients/countrysiderestorationtrustr/assets/CRT_12GiftsOfXmas_Titling.png' />");
}

// 1056287 SA - re-ordering homepage
$(".homeFeedBox1").insertAfter(".homeIntroWrapper");
$(".homeBox7").insertAfter(".homeBox4");